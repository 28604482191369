import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect, useCallback } from 'react';
import { fetchExperiences } from '../lib/api';
import Preloader from './Preloader';

const ROTATION_INTERVAL = 5000; // 5 seconds per experience
const INACTIVITY_TIMEOUT = 10000; // Resume auto-rotation after 10 seconds of inactivity

const RichText = ({ content }) => {
  if (!content) return null;
  
  // For WordPress content, we can safely use dangerouslySetInnerHTML as it comes from our backend
  return (
    <div 
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default function Experience() {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAutoRotating, setIsAutoRotating] = useState(true);
  const [progress, setProgress] = useState(0);
  const [lastInteractionTime, setLastInteractionTime] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1
  });

  const rotateExperience = useCallback(() => {
    if (experiences.length > 0) {
      setActiveIndex((current) => (current + 1) % experiences.length);
      setProgress(0);
    }
  }, [experiences.length]);

  // Check for inactivity and resume auto-rotation
  useEffect(() => {
    if (!isAutoRotating && lastInteractionTime > 0) {
      const checkInactivity = setInterval(() => {
        const timeSinceLastInteraction = Date.now() - lastInteractionTime;
        if (timeSinceLastInteraction >= INACTIVITY_TIMEOUT) {
          setIsAutoRotating(true);
          setProgress(0);
        }
      }, 1000);

      return () => clearInterval(checkInactivity);
    }
  }, [isAutoRotating, lastInteractionTime]);

  // Handle auto-rotation
  useEffect(() => {
    if (!isAutoRotating || !inView || experiences.length === 0) return;

    const progressInterval = setInterval(() => {
      setProgress((current) => {
        if (current >= 100) {
          return 0;
        }
        return current + (100 / (ROTATION_INTERVAL / 100));
      });
    }, 100);

    const rotationInterval = setInterval(rotateExperience, ROTATION_INTERVAL);

    return () => {
      clearInterval(progressInterval);
      clearInterval(rotationInterval);
    };
  }, [isAutoRotating, inView, experiences.length, rotateExperience]);

  // Fetch experiences
  useEffect(() => {
    const getExperiences = async () => {
      try {
        const response = await fetchExperiences();
        setExperiences(response || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching experiences:', error);
        setError('Failed to load experiences');
        setLoading(false);
      }
    };

    getExperiences();
  }, []);

  const handleInteraction = () => {
    setIsAutoRotating(false);
    setProgress(0);
    setLastInteractionTime(Date.now());
  };

  const handleTabClick = (index) => {
    setActiveIndex(index);
    handleInteraction();
  };

  if (loading) return <section ref={ref} className="py-20" id="experience"><div className="max-w-6xl mx-auto px-4"><Preloader type="experience" /></div></section>;
  if (error) return <div className="text-center text-red-600">{error}</div>;
  if (!experiences?.length) return null;

  return (
    <section ref={ref} className="py-20" id="experience">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-6xl font-bold text-white mb-12 text-center">Experience</h2>
        
        {/* Mobile View - Vertical Cards */}
        <div className="md:hidden space-y-6">
          {experiences.map((exp, index) => (
            <motion.div
              key={exp.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="bg-[#111] rounded-2xl p-6 border border-[#333] backdrop-blur-sm"
            >
              <div className="mb-4">
                <h3 className="text-2xl font-bold text-white mb-2">{exp.company}</h3>
                {exp.role && (
                  <h4 className="text-lg font-semibold text-purple-400 mb-1">{exp.role}</h4>
                )}
                {exp.period && (
                  <p className="text-sm text-[#888]">{exp.period}</p>
                )}
              </div>
              <div className="prose prose-invert">
                <RichText content={exp.description} />
              </div>
            </motion.div>
          ))}
        </div>

        {/* Desktop View - Tabs */}
        <div className="hidden md:flex flex-row gap-8">
          {/* Vertical Tabs */}
          <div className="w-64 flex-shrink-0">
            <div className="flex flex-col gap-3">
              {experiences.map((exp, index) => (
                <motion.button
                  key={exp.id}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={handleInteraction}
                  className={`group relative w-full text-left px-6 py-4 rounded-xl transition-all duration-300 ${
                    activeIndex === index
                      ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                      : 'bg-[#111] text-[#666] hover:bg-[#222] hover:text-white'
                  }`}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <h3 className="font-semibold text-lg">{exp.company}</h3>
                  <p className="text-sm opacity-80">{exp.role}</p>
                  {activeIndex === index && isAutoRotating && (
                    <div className="absolute bottom-0 left-0 w-full h-1 overflow-hidden rounded-b-xl">
                      <motion.div
                        className="h-full bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 bg-[length:200%_100%]"
                        initial={{ backgroundPosition: "100% 0" }}
                        animate={{ 
                          backgroundPosition: ["100% 0", "0% 0"],
                          width: `${progress}%` 
                        }}
                        transition={{
                          backgroundPosition: {
                            duration: ROTATION_INTERVAL / 1000,
                            ease: "linear",
                            repeat: Infinity
                          },
                          width: {
                            duration: 0.1,
                            ease: "linear"
                          }
                        }}
                        style={{
                          boxShadow: "0 0 10px rgba(147, 51, 234, 0.5)"
                        }}
                      />
                    </div>
                  )}
                </motion.button>
              ))}
            </div>
          </div>

          {/* Content Area */}
          <AnimatePresence mode="wait">
            <motion.div
              key={activeIndex}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="flex-grow bg-[#111] rounded-2xl p-8 border border-[#333] backdrop-blur-sm"
            >
              <div className="max-w-3xl">
                <div className="mb-6">
                  <h3 className="text-3xl font-bold text-white mb-2">{experiences[activeIndex].company}</h3>
                  {experiences[activeIndex].role && (
                    <h4 className="text-xl font-semibold text-purple-400 mb-1">{experiences[activeIndex].role}</h4>
                  )}
                  {experiences[activeIndex].period && (
                    <p className="text-sm text-[#888]">{experiences[activeIndex].period}</p>
                  )}
                </div>
                
                <div className="mt-6 prose prose-invert">
                  <RichText content={experiences[activeIndex].description} />
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
}
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';
import { fetchAbout } from '../lib/api';
import Preloader from './Preloader';

// Image optimization utility
const optimizeImage = (url, width = 800, quality = 80) => {
  if (!url) return '';
  if (url.includes('optimole.com')) return url;
  
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}w=${width}&q=${quality}&format=webp`;
};

const ImageLoader = () => (
  <div className="absolute inset-0 bg-[#111] overflow-hidden">
    <div className="h-full w-full relative">
      <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-[#222] to-transparent" />
    </div>
  </div>
);

export default function About() {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  });

  useEffect(() => {
    const getAboutData = async () => {
      try {
        const data = await fetchAbout();
        setAboutData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching about data:', error);
        setError('Failed to load about section');
        setLoading(false);
      }
    };

    getAboutData();
  }, []);

  if (loading) return <section className="py-20" id="about">
      <div className="max-w-6xl mx-auto px-4">
    <Preloader type="about" />

      </div>
    </section>;
  if (error) return <div className="text-center text-red-600">{error}</div>;
  if (!aboutData) return null;

  return (
    <section className="py-20" id="about">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="relative"
          >
            {aboutData?.image?.url && (
              <div className="aspect-[4/5] rounded-2xl overflow-hidden relative group">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
                <img
                  src={optimizeImage(aboutData.image.url, 800)}
                  alt={aboutData.image.alt || "Tamara Andreevska"}
                  className={`w-full h-full object-cover transition-all duration-300 ${
                    imageLoaded ? 'opacity-100' : 'opacity-0'
                  }`}
                  onLoad={() => setImageLoaded(true)}
                  loading="eager"
                />
                {!imageLoaded && <ImageLoader />}
              </div>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div className="space-y-4">
              <h2 className="text-6xl font-bold text-white mb-12">About Me</h2>
              <div 
                className="about-content text-lg"
                dangerouslySetInnerHTML={{ __html: aboutData.description }}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
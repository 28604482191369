import React from 'react';
import { motion } from 'framer-motion';

const SkeletonPulse = () => (
  <div className="absolute inset-0 bg-[#111] overflow-hidden">
    <div className="h-full w-full relative">
      <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-[#222] to-transparent" />
    </div>
  </div>
);

const Preloader = ({ type = 'default' }) => {
  if (type === 'projects') {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <div key={i} className="relative">
            <div className="aspect-[4/3] rounded-2xl overflow-hidden bg-[#111] relative">
              <SkeletonPulse />
            </div>
            <div className="mt-4 space-y-3">
              <div className="h-6 bg-[#111] rounded-lg relative overflow-hidden w-3/4">
                <SkeletonPulse />
              </div>
              <div className="h-4 bg-[#111] rounded-lg relative overflow-hidden w-1/2">
                <SkeletonPulse />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'about') {
    return (
      <div className="grid md:grid-cols-2 gap-12">
        <div className="aspect-[4/5] rounded-2xl overflow-hidden bg-[#111] relative">
          <SkeletonPulse />
        </div>
        <div className="space-y-4">
          <div className="h-12 bg-[#111] rounded-lg relative overflow-hidden w-1/2">
            <SkeletonPulse />
          </div>
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-4 bg-[#111] rounded-lg relative overflow-hidden">
              <SkeletonPulse />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (type === 'experience') {
    return (
      <div className="space-y-8">
        {[1, 2, 3].map((i) => (
          <div key={i} className="flex flex-col md:flex-row gap-8">
            <div className="md:w-1/3 space-y-3">
              <div className="h-6 bg-[#111] rounded-lg relative overflow-hidden w-3/4">
                <SkeletonPulse />
              </div>
              <div className="h-4 bg-[#111] rounded-lg relative overflow-hidden w-1/2">
                <SkeletonPulse />
              </div>
            </div>
            <div className="md:w-2/3 space-y-3">
              <div className="h-6 bg-[#111] rounded-lg relative overflow-hidden">
                <SkeletonPulse />
              </div>
              <div className="h-4 bg-[#111] rounded-lg relative overflow-hidden w-5/6">
                <SkeletonPulse />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Default skeleton
  return (
    <div className="space-y-8">
      <div className="h-8 bg-[#111] rounded-lg relative overflow-hidden w-1/3">
        <SkeletonPulse />
      </div>
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="h-4 bg-[#111] rounded-lg relative overflow-hidden">
            <SkeletonPulse />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Preloader;

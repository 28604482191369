import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const FloatingElements = () => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    // Generate random elements
    const generateElements = () => {
      const newElements = [];
      const shapes = [
        // UI/UX related shapes
        <path d="M10 10h20v20H10z" />, // square
        <path d="M25 10 L40 40 L10 40 Z" />, // triangle
        <circle cx="20" cy="20" r="10" />, // circle
        <path d="M10 20h30" />, // line
        <path d="M15 15h10v10h-10z" />, // small square
        <path d="M20 10c5.523 0 10 4.477 10 10s-4.477 10-10 10S10 25.523 10 20 14.477 10 20 10z" />, // perfect circle
        <path d="M10 20c0-5.523 4.477-10 10-10s10 4.477 10 10" />, // arc
        <path d="M10 10l20 20M10 30l20-20" />, // cross
      ];

      for (let i = 0; i < 15; i++) {
        const randomShape = shapes[Math.floor(Math.random() * shapes.length)];
        const size = Math.random() * 30 + 10;
        const startX = Math.random() * window.innerWidth;
        const startY = -50;
        const duration = Math.random() * 20 + 10;
        const delay = Math.random() * 10;

        newElements.push({
          id: i,
          shape: randomShape,
          size,
          startX,
          startY,
          duration,
          delay,
        });
      }
      setElements(newElements);
    };

    generateElements();
    const interval = setInterval(generateElements, 20000); // Regenerate every 20 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none z-0 overflow-hidden">
      {elements.map((element) => (
        <motion.div
          key={element.id}
          initial={{ 
            x: element.startX,
            y: element.startY,
            opacity: 0,
            scale: 0,
            rotate: 0
          }}
          animate={{
            x: element.startX + (Math.random() * 200 - 100),
            y: window.innerHeight + 50,
            opacity: [0, 0.8, 0],
            scale: [0, 1, 0.5],
            rotate: Math.random() * 360
          }}
          transition={{
            duration: element.duration,
            delay: element.delay,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute"
        >
          <svg
            width={element.size}
            height={element.size}
            viewBox="0 0 40 40"
            fill="none"
            stroke="currentColor"
            className="text-purple-400/40"
            strokeWidth="2"
          >
            {element.shape}
          </svg>
        </motion.div>
      ))}
    </div>
  );
};

export default FloatingElements;

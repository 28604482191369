import { motion } from 'framer-motion';
import ContactForm from './ContactForm';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative ">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5"></div>

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/80 to-transparent"></div>

      {/* Content */}
      <section id="contact" className="relative">
        <div className="relative max-w-6xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-2 gap-12 mb-12">
            {/* Contact Info */}
            <div>
              <h3 className="text-white font-bold text-4xl mb-6">Get in Touch</h3>
              <p className="text-gray-400 mb-8">
                I'm always interested in hearing about new projects and opportunities.
                Drop me a message, and I'll get back to you as soon as possible.
              </p>
              <ul className="space-y-4">
                <li>
                  <a
                    href="mailto:info@tamaraandreevska.com"
                    className="text-gray-400 hover:text-purple-400 transition-colors flex items-center gap-2"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    info@tamaraandreevska.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/tamara-andreevska/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-purple-400 transition-colors flex items-center gap-2"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                    </svg>
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Form */}
            <div>
              <ContactForm />
            </div>
          </div>

          {/* Copyright */}
          <div className="border-t border-gray-800 pt-8 mt-8">
            <motion.p 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="text-center text-gray-500"
            >
              &copy; {currentYear} Tamara Andreevska. All rights reserved.
            </motion.p>
          </div>
        </div>
      </section>
    </footer>
  );
}
import { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Dialog } from '@headlessui/react';
import { fetchProjects } from '../lib/api';
import Preloader from './Preloader';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Image optimization utility
const optimizeImage = (url, width = 800, quality = 80) => {
  if (!url) return '';
  // If using Optimole, it will already handle optimization
  if (url.includes('optimole.com')) return url;
  
  // Add query parameters for WordPress default image scaling
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}w=${width}&q=${quality}&format=webp`;
};

// Preload images utility
const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const ImageLoader = () => (
  <div className="absolute inset-0 bg-[#111] overflow-hidden">
    <div className="h-full w-full relative">
      <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-[#222] to-transparent" />
    </div>
  </div>
);

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState(new Set());

  // Preload next project's images
  const preloadNextProject = useCallback(async (currentIndex) => {
    if (!projects.length) return;
    const nextIndex = (currentIndex + 1) % projects.length;
    const nextProject = projects[nextIndex];
    
    try {
      if (nextProject.thumbnail) {
        await preloadImage(optimizeImage(nextProject.thumbnail.url, 800));
      }
      if (nextProject.images?.length) {
        await Promise.all(
          nextProject.images.slice(0, 2).map(img => 
            preloadImage(optimizeImage(img.url, 1200))
          )
        );
      }
    } catch (error) {
      console.error('Error preloading images:', error);
    }
  }, [projects]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await fetchProjects();
        if (response) {
          setProjects(response);
        } else {
          console.error('No data in response');
          setError('No projects data available');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Failed to load projects');
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  const handleImageClick = (index) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  if (loading) return <section className="py-20" id="projects">
      <div className="max-w-6xl mx-auto px-4">
        <Preloader type="projects" />
      </div>
    </section>;
  if (error) return <div className="text-center text-red-600">{error}</div>;
  if (!projects?.length) return null;

  return (
    <section className="py-20" id="projects">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-6xl font-bold text-white mb-12 text-center">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <motion.div
              key={project.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="group relative bg-[#111] rounded-2xl overflow-hidden border border-[#333] hover:border-purple-500/50 transition-colors cursor-pointer"
              onClick={() => {
                setSelectedProject(project);
                preloadNextProject(index);
              }}
            >
              {project.thumbnail && (
                <div className="aspect-video relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-fuchsia-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
                  <img
                    src={optimizeImage(project.thumbnail.url, 800)}
                    alt={project.thumbnail.alt || project.title}
                    className={`w-full h-full object-cover group-hover:scale-105 transition-all duration-300 ${
                      loadedImages.has(project.thumbnail.url) ? 'opacity-100' : 'opacity-0'
                    }`}
                    loading="lazy"
                    onLoad={() => setLoadedImages(prev => new Set([...prev, project.thumbnail.url]))}
                  />
                  {!loadedImages.has(project.thumbnail.url) && <ImageLoader />}
                </div>
              )}
              <div className="p-6">
                <h3 className="text-xl font-bold text-white mb-2 group-hover:text-purple-400 transition-colors">
                  {project.title}
                </h3>
                <p className="text-[#888] mb-4">{project.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedProject && (
            <Dialog
              as={motion.div}
              static
              open={true}
              onClose={() => setSelectedProject(null)}
              className="fixed inset-0 z-50 overflow-y-auto"
            >
              <div className="flex items-center justify-center min-h-screen p-4">
                <Dialog.Overlay
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black/80 backdrop-blur-sm"
                />

                <motion.div
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  className="bg-[#111] rounded-2xl max-w-4xl w-full relative border border-[#333] overflow-hidden"
                >
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-6">
                      <Dialog.Title className="text-2xl font-bold text-white">
                        {selectedProject.title}
                      </Dialog.Title>
                      <button
                        onClick={() => setSelectedProject(null)}
                        className="text-[#888] hover:text-white transition-colors"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>

                    {selectedProject.images?.length > 0 && (
                      <div className="mb-6 relative project-slider">
                        <Swiper
                          modules={[Navigation, Pagination]}
                          navigation={true}
                          pagination={{
                            clickable: true,
                          }}
                          className="h-[400px] rounded-xl relative"
                          onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
                        >
                          {selectedProject.images.map((image, index) => (
                            <SwiperSlide key={index} className="relative">
                              <img
                                src={optimizeImage(image.url, 1200)}
                                alt={image.alt || `${selectedProject.title} - Image ${index + 1}`}
                                className={`w-full h-full object-contain cursor-pointer transition-opacity duration-300 ${
                                  loadedImages.has(image.url) ? 'opacity-100' : 'opacity-0'
                                }`}
                                loading={index === 0 ? "eager" : "lazy"}
                                onLoad={() => setLoadedImages(prev => new Set([...prev, image.url]))}
                                onClick={() => handleImageClick(index)}
                              />
                              {!loadedImages.has(image.url) && <ImageLoader />}
                            </SwiperSlide>
                          ))}
                          <button
                            onClick={() => handleImageClick(currentSlideIndex)}
                            className="absolute top-4 right-4 z-10 text-[#888] hover:text-white transition-colors bg-purple-500/20 hover:bg-purple-500/30 backdrop-blur-sm border border-purple-400/30 rounded-full p-2"
                          >
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                              />
                            </svg>
                          </button>
                        </Swiper>
                      </div>
                    )}

                    <div className="prose prose-invert max-w-none">
                      <div dangerouslySetInnerHTML={{ __html: selectedProject.details }} />
                    </div>

                    {selectedProject.role && (
                      <div className="mt-6">
                        <h4 className="text-lg font-semibold text-white mb-2">Role</h4>
                        <p className="text-[#888]">{selectedProject.role}</p>
                      </div>
                    )}

                    {selectedProject.duration && (
                      <div className="mt-4">
                        <h4 className="text-lg font-semibold text-white mb-2">Duration</h4>
                        <p className="text-[#888]">{selectedProject.duration}</p>
                      </div>
                    )}
                  </div>
                </motion.div>
              </div>
            </Dialog>
          )}
        </AnimatePresence>

        <Lightbox
          open={isLightboxOpen}
          close={() => setIsLightboxOpen(false)}
          index={lightboxIndex}
          slides={selectedProject?.images?.map(image => ({
            src: optimizeImage(image.url, 1920),
            alt: image.alt || selectedProject.title,
            loading: 'lazy'
          })) || []}
        />
      </div>
    </section>
  );
}
import { useState, useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Footer from './components/Footer';
import FloatingElements from './components/FloatingElements';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  if (isLoading) {
    return (
      <div role="alert" aria-label="Loading portfolio" className="fixed inset-0 bg-black flex items-center justify-center">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-black rounded-full animate-spin" aria-hidden="true"></div>
      </div>
    );
  }

  return (
    <div className="bg-black min-h-screen">
      <FloatingElements aria-hidden="true" />
      <Header role="banner" />
      <main role="main" id="main-content">
        <article itemScope itemType="https://schema.org/Article">
          <Hero />
          <About itemProp="about" />
          <Experience itemProp="workExperience" />
          <Projects itemProp="portfolio" />
        </article>
      </main>
      <Footer role="contentinfo" />
    </div>
  );
}

export default App;
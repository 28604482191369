import { motion } from 'framer-motion';

export default function Hero() {
  return (
    <section className="relative min-h-[80svh] flex items-center justify-center overflow-hidden bg-gray-900">
      {/* Background Blobs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full animate-blob mix-blend-difference">
          <div className="absolute inset-0 rounded-full bg-indigo-600 opacity-70 blur-3xl"></div>
        </div>
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full animate-blob animation-delay-2000 mix-blend-difference">
          <div className="absolute inset-0 rounded-full bg-purple-600 opacity-70 blur-3xl"></div>
        </div>
        <div className="absolute top-1/2 left-1/2 w-full h-full animate-blob animation-delay-4000 mix-blend-difference">
          <div className="absolute inset-0 rounded-full bg-pink-600 opacity-70 blur-3xl"></div>
        </div>
      </div>

      {/* Content */}
      <div className="relative max-w-6xl mx-auto px-4 py-32 sm:px-6 lg:px-8">
        <div className="text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-5xl md:text-7xl font-bold text-white mb-6"
          >
            Hi, I'm Tamara
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto"
          >
            A passionate UI/UX designer crafting beautiful and functional digital experiences
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <a
              href="#projects"
              className="inline-block bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition-colors duration-300"
            >
              View My Work
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
}